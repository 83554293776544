import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, TemplateRef, Directive, Inject, Output, ContentChild, NgModule } from '@angular/core';

/**
 * Defer postpones the loading the content that is initially not in the viewport until it becomes visible on scroll.
 * @group Components
 */
class DeferredLoader {
  document;
  platformId;
  el;
  renderer;
  viewContainer;
  cd;
  /**
   * Callback to invoke when deferred content is loaded.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onLoad = new EventEmitter();
  template;
  documentScrollListener;
  view;
  window;
  constructor(document, platformId, el, renderer, viewContainer, cd) {
    this.document = document;
    this.platformId = platformId;
    this.el = el;
    this.renderer = renderer;
    this.viewContainer = viewContainer;
    this.cd = cd;
    this.window = this.document.defaultView;
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.shouldLoad()) {
        this.load();
      }
      if (!this.isLoaded()) {
        this.documentScrollListener = this.renderer.listen(this.window, 'scroll', () => {
          if (this.shouldLoad()) {
            this.load();
            this.documentScrollListener && this.documentScrollListener();
            this.documentScrollListener = null;
          }
        });
      }
    }
  }
  shouldLoad() {
    if (this.isLoaded()) {
      return false;
    } else {
      let rect = this.el.nativeElement.getBoundingClientRect();
      let docElement = this.document.documentElement;
      let winHeight = docElement.clientHeight;
      return winHeight >= rect.top;
    }
  }
  load() {
    this.view = this.viewContainer.createEmbeddedView(this.template);
    this.onLoad.emit();
    this.cd.detectChanges();
  }
  isLoaded() {
    return this.view != null && isPlatformBrowser(this.platformId);
  }
  ngOnDestroy() {
    this.view = null;
    if (this.documentScrollListener) {
      this.documentScrollListener();
    }
  }
  static ɵfac = function DeferredLoader_Factory(t) {
    return new (t || DeferredLoader)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DeferredLoader,
    selectors: [["", "pDefer", ""]],
    contentQueries: function DeferredLoader_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TemplateRef, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    outputs: {
      onLoad: "onLoad"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DeferredLoader, [{
    type: Directive,
    args: [{
      selector: '[pDefer]',
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    onLoad: [{
      type: Output
    }],
    template: [{
      type: ContentChild,
      args: [TemplateRef]
    }]
  });
})();
class DeferModule {
  static ɵfac = function DeferModule_Factory(t) {
    return new (t || DeferModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: DeferModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DeferModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [DeferredLoader],
      declarations: [DeferredLoader]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DeferModule, DeferredLoader };
