import {TypeOfToken} from "./playground.model";

export namespace PlaygroundActions{
  export class Loading {
    static readonly type = '[Playground] Set As Loading';
  }
  export class Working {
    static readonly type = '[Playground] Set As Working';
  }
  export class Done {
    static readonly type = '[Playground] Set As Done';
  }
  export class  ApplyToken {
    static readonly type = '[Playground] Apply Token';
    constructor(public token: string) {}
  }
  export class  ApplyTokenType {
    static readonly type = '[Playground] Apply Token Type';
    constructor(public tokenType: TypeOfToken) {}
  }
  export class FetchToken {
    static readonly type = '[Playground] Get Token';
  }

}


