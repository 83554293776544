
export namespace StatusActions{
  export class Loading {
    static readonly type = '[Status] Set As Loading';
  }

  export class Working {
    static readonly type = '[Status] Set As Working';
  }

  export class Done {
    static readonly type = '[Status] Set As Done';
  }
  export class GetHealthStatus {
    static readonly type = '[Status] Get Health Status';
  }


}


