import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import { DomHandler } from 'primeng/dom';

/**
 * Animate manages PrimeFlex CSS classes declaratively to during enter/leave animations on scroll or on page load.
 * @group Components
 */
class Animate {
  host;
  el;
  renderer;
  /**
   * Selector to define the CSS class for enter animation.
   * @group Props
   */
  enterClass;
  /**
   * Selector to define the CSS class for leave animation.
   * @group Props
   */
  leaveClass;
  observer;
  timeout;
  constructor(host, el, renderer) {
    this.host = host;
    this.el = el;
    this.renderer = renderer;
  }
  ngOnInit() {
    console.log('pAnimate directive is deprecated in 16.7.0 and will be removed in the future. Use pAnimateOnScroll directive instead');
  }
  ngAfterViewInit() {
    this.bindIntersectionObserver();
  }
  bindIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(el => this.isVisible(el), options);
    this.observer.observe(this.host.nativeElement);
  }
  isVisible(element) {
    const [intersectionObserverEntry] = element;
    intersectionObserverEntry.isIntersecting ? this.enter() : this.leave();
  }
  enter() {
    this.host.nativeElement.style.visibility = 'visible';
    DomHandler.addClass(this.host.nativeElement, this.enterClass);
  }
  leave() {
    DomHandler.removeClass(this.host.nativeElement, this.enterClass);
    if (this.leaveClass) {
      DomHandler.addClass(this.host.nativeElement, this.leaveClass);
    }
    const animationDuration = this.host.nativeElement.style.animationDuration || 500;
    this.timeout = setTimeout(() => {
      this.host.nativeElement.style.visibility = 'hidden';
    }, animationDuration);
  }
  unbindIntersectionObserver() {
    if (this.observer) {
      this.observer.unobserve(this.host.nativeElement);
    }
  }
  ngOnDestroy() {
    this.unbindIntersectionObserver();
    clearTimeout(this.timeout);
  }
  static ɵfac = function Animate_Factory(t) {
    return new (t || Animate)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: Animate,
    selectors: [["", "pAnimate", ""]],
    hostVars: 2,
    hostBindings: function Animate_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("p-animate", true);
      }
    },
    inputs: {
      enterClass: "enterClass",
      leaveClass: "leaveClass"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Animate, [{
    type: Directive,
    args: [{
      selector: '[pAnimate]',
      host: {
        '[class.p-animate]': 'true'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    enterClass: [{
      type: Input
    }],
    leaveClass: [{
      type: Input
    }]
  });
})();
class AnimateModule {
  static ɵfac = function AnimateModule_Factory(t) {
    return new (t || AnimateModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AnimateModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimateModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Animate],
      declarations: [Animate]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Animate, AnimateModule };
