
export interface IPlaygroundStateModel {
  loading: boolean;
  busy: boolean;
  token: string | null;
  tokenType: TypeOfToken | null;
}
export type TokenResultType  = { token: string;}
export type TypeOfToken = "Element" | "OneProfile" | "OasToken"
export const TypeOfTokenKeys : Record<TypeOfToken, TypeOfToken> ={
  Element: "Element",
  OneProfile: "OneProfile",
  OasToken: "OasToken"
}
export const TypeOfTokenBaseApi : Record<TypeOfToken, string> = {
  Element: 'token',
  OneProfile: 'one-profile',
  OasToken: 'oas-token'
}
export const TokenSchemas:Record<TypeOfToken, string> ={
  Element: 'Bearer',
  OneProfile:'OneProfileToken',
  OasToken: "OasToken"
}
