import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HealthStatusType, IStatusStateModel} from './status.model';
import {StatusActions} from './status.actions';
import {mergeMap, tap} from 'rxjs';

@State<IStatusStateModel>({
  name: 'status',
  defaults: <IStatusStateModel>{
    loading: false,
    busy: false,
    healthStatus: null
  }
})
@Injectable()
export class StatusState implements NgxsOnInit {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    ctx.dispatch(new StatusActions.GetHealthStatus());
  }

  get basePath() {
    return 'statuses';
  }

  @Selector()
  static IsLoading(state: IStatusStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IStatusStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getCurrentHealthStatus(state: IStatusStateModel): HealthStatusType | null {
    return state.healthStatus;
  }

  @Action(StatusActions.Done)
  onDone(ctx: StateContext<IStatusStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(StatusActions.Loading)
  onLoading(ctx: StateContext<IStatusStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(StatusActions.Working)
  onWorking(ctx: StateContext<IStatusStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(StatusActions.GetHealthStatus)
  getHealthStatus(ctx: StateContext<IStatusStateModel>) {
    return ctx.dispatch(new StatusActions.Loading()).pipe(
      mergeMap(() => this.httpClient.get<HealthStatusType>(`${this.basePath}/health`)),
      tap((healthStatus: HealthStatusType) => {
        ctx.patchState({ healthStatus})
      }),
      mergeMap(() => ctx.dispatch(new StatusActions.Done()))
    )
  }


}
