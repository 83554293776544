export type TargetEnvironmentType = "Local" | "Dev" | "Stage" | "Prod";
export const TargetEnvironmentKeys : Record<TargetEnvironmentType, TargetEnvironmentType> ={
  Local: "Local",
  Dev: "Dev",
  Stage: "Stage",
  Prod: "Prod"
}
export const TargetEnvironments : Record<TargetEnvironmentType, string> = {
  Local: "http://localhost:54625/v1",
  Dev: "https://global-entity-service-webapi.review.cloud.orionadvisor.com/v1",
  Stage: "https://global-entity-service-webapi.review.cloud.orionadvisor.com/v1",
  Prod: "https://global-entity-service-webapi.review.cloud.orionadvisor.com/v1"
};

