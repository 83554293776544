import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  IPlaygroundStateModel,
  TokenResultType,
  TokenSchemas,
  TypeOfToken,
  TypeOfTokenBaseApi
} from './playground.model';
import {PlaygroundActions} from './playground.actions';
import {EMPTY, mergeMap, tap} from "rxjs";
import {PlaygroundHelper} from "../../shared";


@State<IPlaygroundStateModel>({
  name: 'playground',
  defaults: <IPlaygroundStateModel>{
    loading: false,
    busy: false,
    token: null,
    tokenType: null
  }
})
@Injectable()
export class PlaygroundState implements NgxsOnInit {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    if (PlaygroundHelper.HasPersistedToken()) {
      const token = PlaygroundHelper.RetrieveToken();
      // @ts-ignore
      ctx.patchState({token})
    }
  }

  get basePath() {
    return 'sample';
  }

  @Selector()
  static IsLoading(state: IPlaygroundStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IPlaygroundStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getToken(state: IPlaygroundStateModel): string | null {
    return state.token;
  }

  @Selector()
  static getTokenType(state: IPlaygroundStateModel): TypeOfToken | null {
    return state.tokenType;
  }

  @Action(PlaygroundActions.Done)
  onDone(ctx: StateContext<IPlaygroundStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(PlaygroundActions.Loading)
  onLoading(ctx: StateContext<IPlaygroundStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(PlaygroundActions.ApplyToken)
  onApplyToken(ctx: StateContext<IPlaygroundStateModel>, {token}: PlaygroundActions.ApplyToken) {
    const {tokenType} = ctx.getState();
    const schema = (tokenType) ? TokenSchemas[tokenType] :TokenSchemas.Element
    const fullToken = `${schema} ${token}`;
    ctx.patchState({
      token: fullToken
    });
    PlaygroundHelper.PersistToken(fullToken);
  }

  @Action(PlaygroundActions.ApplyTokenType)
  onApplyTokenType(ctx: StateContext<IPlaygroundStateModel>, {tokenType}: PlaygroundActions.ApplyTokenType) {
    ctx.patchState({
      tokenType
    });
  }

  @Action(PlaygroundActions.Working)
  onWorking(ctx: StateContext<IPlaygroundStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(PlaygroundActions.FetchToken)
  onFetchToken(ctx: StateContext<IPlaygroundStateModel>) {
    const {tokenType} = ctx.getState();
    if (tokenType) {
      const baseApiPath = TypeOfTokenBaseApi[tokenType]
      return ctx.dispatch(new PlaygroundActions.Working()).pipe(
        mergeMap(() => {
          return this.httpClient.get<TokenResultType>(`${this.basePath}/${baseApiPath}`);
        }),
        tap((response) => {
          const {token} = response;
          ctx.dispatch(new PlaygroundActions.ApplyToken(token));
        }),
        mergeMap(() => ctx.dispatch(new PlaygroundActions.Done()))
      )
    }
    return EMPTY;
  }


}
